import React from 'react';

import { theme } from '@headway/ui/theme';

import { StaticImageData } from './Image/Image';
import { Quote } from './Quote';

export const TestimonialMention: React.FC<React.PropsWithChildren> = (
  props
) => <span css={{ color: theme.color.primary }} {...props} />;

export type TestimonialProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'title'
> & {
  photoSrc?: StaticImageData;
  name?: string;
  title: React.ReactNode;
  quote: React.ReactNode;
  isHidden?: boolean;
  Image?: React.ComponentType;
};

export const Testimonial: React.FC<TestimonialProps> = ({
  photoSrc,
  name,
  title,
  quote,
  Image,
  isHidden,
  ...rest
}) => {
  const QuoteElement = (
    <Quote
      title={title}
      quote={quote}
      photoSrc={photoSrc}
      name={name}
      photoRadius={60}
      Image={Image}
    />
  );
  return isHidden ? (
    QuoteElement
  ) : (
    <a
      tabIndex={0}
      target="_blank"
      rel="noopener noreferrer"
      css={{
        width: 'calc(100% - 6px)',
      }}
      {...rest}
    >
      {QuoteElement}
    </a>
  );
};
